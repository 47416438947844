import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  // loader indicator
  state: {
    isLoader: false,
  },
  // loader indicator
  mutations: {
    enableLoader(state) {
      state.isLoader = true
    },
    disableLoader(state) {
      state.isLoader = false
    },
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
