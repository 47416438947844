export default [
  {
    path: '/clients/list',
    name: 'apps-clients-list',
    parent: 'clients',
    component: () => import('@/views/Clients/clients-list/ClientsList.vue'),
    meta: {
      navActiveLink: 'clients'
    }
  },
  {
    path: '/clients/view/:id',
    name: 'apps-clients-view',
    component: () => import('@/views/Clients/clients-view/UsersView.vue'),
    meta: {
      navActiveLink: 'clients',
    }
  },
  {
    path: '/clients/edit/:id',
    name: 'apps-clients-edit',
    component: () => import('@/views/Clients/clients-edit/UsersEdit.vue'),
    meta: {
      navActiveLink: 'clients'
    }
  },
  {
    path: '/memberships/list',
    name: 'apps-memberships-list',
    component: () => import('@/views/Memberships/memberships-list/MembershipsList.vue'),
    meta: {
      navActiveLink: 'memberships'
    }
  },
  {
    path: '/memberships/add/:id',
    name: 'apps-memberships-add',
    component: () => import('@/views/Memberships/memberships-add/MembershipAddNew.vue'),
    meta: {
      navActiveLink: 'memberships'
    }
  },
  {
    path: '/memberships/edit/:id',
    name: 'apps-memberships-edit',
    component: () => import('@/views/Memberships/memberships-edit/MembershipEdit.vue'),
    meta: {
      navActiveLink: 'memberships'
    }
  },
  {
    path: '/memberships/delete/:id',
    name: 'apps-memberships-delete',
    component: () => import('@/views/Memberships/memberships-delete/MembershipDelete.vue'),
    meta: {
      navActiveLink: 'memberships'
    }
  },
  {
    path: '/plans/list',
    name: 'apps-plans-list',
    component: () => import('@/views/Plans/plans-list/PlansList.vue'),
    meta: {
      navActiveLink: 'plans'
    }
  },
  {
    path: '/plans/edit/:id',
    name: 'apps-plans-edit',
    component: () => import('@/views/Plans/plans-edit/PlanEdit.vue'),
    meta: {
      navActiveLink: 'plans'
    }
  },
  {
    path: '/attendance/list',
    name: 'apps-attendances-list',
    component: () => import('@/views/Attendances/attendances-list/AttendancesList.vue'),
    meta: {
      navActiveLink: 'attendance'
    }
  },
  {
    path: '/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/Users/users-list/UsersList.vue'),
    meta: {
      navActiveLink: 'users'
    }
  },
  {
    path: '/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/Users/users-profile-edit/UserProfileEdit.vue'),
    meta: {
      navActiveLink: 'users'
    }
  }
]
