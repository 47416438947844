import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './routes/apps'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    {
      path: '/',
      name: 'clients',
      component: () => import('@/views/Clients/clients-list/ClientsList.vue'),
      meta: {
        parent: 'clients',
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
      },
    },
    {
      path: '/plans',
      name: 'plans',
      component: () => import('@/views/Plans/plans-list/PlansList.vue'),
      meta: {
        pageTitle: 'Planes',
        breadcrumb: [
          {
            text: 'Planes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/memberships',
      name: 'memberships',
      component: () => import('@/views/Memberships/memberships-list/MembershipsList.vue'),
      meta: {
        pageTitle: 'Contrataciones',
        breadcrumb: [
          {
            text: 'Contrataciones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/attendances',
      name: 'attendances',
      component: () => import('@/views/Attendances/attendances-list/AttendancesList.vue'),
      meta: {
        pageTitle: 'Asistencias',
        breadcrumb: [
          {
            text: 'Asistencias',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'Routines',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Rutinas',
        breadcrumb: [
          {
            text: 'Rutinas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userData');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
